import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../view/index.vue'
import me from '../view/me.vue'
import NavBox from '../view/NavBox.vue'
import work from '../view/work.vue'
import Apply from '../view/Apply.vue'
import fidelidad from '../view/fidelidad.vue'
import points from '../view/points.vue'
import Prestamia from '../view/Prestamia.vue'
import Use from '../view/Use.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [{
        path: '/NavBox',
        name: 'NavBox',
        component: NavBox,
        children: [
            {
                path: 'index',
                name: 'index',
                component: index,
            },
            {
                path: 'me',
                name: 'me',
                component: me,
            },
            {
                path: 'work',
                name: 'work',
                component: work,
            },
            {
                path: 'Apply',
                name: 'Apply',
                component: Apply,
            },
            {
                path: 'fidelidad',
                name: 'fidelidad',
                component: fidelidad,
            },
            {
                path: 'points',
                name: 'points',
                component: points,
            },
            {
                path: 'Prestamia',
                name: 'Prestamia',
                component: Prestamia,
            },
            {
                path: 'Use',
                name: 'Use',
                component: Use,
            },
        ]
    },
    {
        path: '',
        redirect:'/NavBox/index'
    },

]

const router = new VueRouter({
    routes
})

export default router