<template>
    
    <div>
        <div class="header">
            <div class="header_mid">
                <div class="header_left">
                <h2>¿Necesitas más dinero?</h2>
                <h2>Amplía la cantidad de tu préstamo online</h2>
                <hr>
                
                <p>Una vez hayas devuelto tu préstamo rápido y después de revisar automáticamente tu historial de pagos, podremos ofrecerte la posibilidad de aumentar la cantidad de tu crédito rápido. Esta funcionalidad la tendrás disponible en tu área de cliente, por lo que solo necesitas entrar y solicitarla.</p>

                <p class="header_left_tit">¿Quién puede solicitar una cantidad adicional al préstamo al instante?</p>
                <p>— Si ya eres cliente Prestamia, lo único que debes hacer es acceder a tu área de cliente en donde encontrarás esta opción personalizada. Desde ahí puedes solicitar tu cantidad adicional de manera secilla y rápida.</p>
                <p class="header_left_tit">¿Cuál es la cantidad adicional que puedo solicitar?</p>
                <p>— En función de tus datos, nuestro sistema te asignará una cantidad adicional de manera automática.</p>
                <p class="header_left_tit">¿Qué sucede con mi actual préstamo rápido si solicito una cantidad adicional?</p>
                <p>— La cantidad adicional que solicites se sumará a tu crédito actual, de modo que se mantendrá sin cambios la fecha de vencimiento de tu préstamo online original.</p>

                <p class="header_left_tit">¿Cuántas veces puedo solicitar una cantidad adicional?</p>
                <p>— Puedes solicitar una cantidad adicional tantas veces como quieras hasta llegar al máximo permitido por tu límite de crédito.</p>
                <!-- <button class="btn">área de clientes</button> -->
                </div>
                <div class="headar_right">
                    <p><router-link to="/NavBox/work">Consigue tu crédito online en el acto y en tan sólo 3 sencillos pasos</router-link> </p>
                    <p><router-link to="/NavBox/points">¿Cómo ampliar la cantidad del préstamo?</router-link> </p>
                    <p><router-link to="/NavBox/Prestamia">¿Por qué elegir Prestamia?</router-link> </p>
                    <p><router-link to="/NavBox/Use">Solicita con responsabilidad</router-link> </p>
                </div>
            </div>
        </div>
        <div class="abcss">
        <div class="abcss_middle">
          <div class="abcss_left">
              <h4>¿Necesitas ayuda?</h4>
              <p>¡Resolvemos todas tus dudas!</p>
              <p><a href="https://www.prestamiaes.com" style="color: #fff;text-decoration: underline;">Consulta las preguntas más frecuentes</a></p>
              <p><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiacreditosrapidoss/privacy.html">privacy</a></p>
              <p style="padding: 1vw 0px;"><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiaess/terms.html">Términos y condiciones</a></p>
          </div>
          <div class="abcss_right">
            <div class="tt">
              <!-- <p>+34 643251527</p> -->
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/phone.png" alt=""></span> +34 643251527</p>
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/sms.png" alt=""></span>officialservicio@prestamiaes.com</p>
            </div>
            <div class="jj">
              <p>Horario de atención:</p>
              <p>‍Lunes – Viernes / 8:00 – 21:00</p>
              <p>Sábado / 9:00 – 20:00</p>
              <p>Domingo / 9:00 – 19:00</p>
              <p>Festivos nacionales / 9:00 – 14:00</p>
            </div>
          </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    metaInfo : {
    title:'Cómo funciona',
    meta:[
    {
        name:'Cómo funciona | España',
        content:'Amplía la cantidad de tu préstamo online'
    },
    {
      name:'¿Cuántas veces puedo solicitar una cantidad adicional?',
      content:'Si ya eres cliente Prestamia, lo único que debes hacer es acceder a tu área de cliente en donde encontrarás esta opción personalizada. Desde ahí puedes solicitar tu cantidad adicional de manera secilla y rápida.'
    },
    {
      name:'¿Cómo ampliar la cantidad del préstamo?',
      content:'Puedes solicitar una cantidad adicional tantas veces como quieras hasta llegar al máximo permitido por tu límite de crédito'
    },]
  },
    
}
</script>

<style scoped>
@import url(../assets/css/index.css);
.header {
    background-color: #f3f4f8;
}
.header_mid {
    display: flex;
    padding:  0px 10%; 
    padding-top: 100px;
}
.header_left {
    padding-right: 50px;
}
.header_left > h2 {
    font-size: 30px;
}
.header_left > hr {
    width: 100px;
    height: 5px;
    margin: 50px 0px;
    border-radius: 10px;
    background-color: #116c3a;
}


.header_left  > p > span {
    margin-right: 10px;
    padding: 7px;
    border-radius: 10px;
    border: 2px solid #a7cc46;
    color: #8caf2c;
}
.header_left  > p {
    margin: 30px 0px;
    font-size: 16px;
    
}
.header_left_tit {
    margin: 60px 0px !important;
    font-weight: 700;
    font-size: 24px !important;
}
.headar_right {
    width: 700px;
    height: 100%;
    background-color: #a7cc46;
    border-radius: 10px;
}
.headar_right > p {
    padding: 20px 50px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    color: #fff;
    font-weight: 700;
}

.headar_right > p:hover {
    background-color: #8caf2c;
    border-radius: 10px;
}
.btn {
    padding: 17px 50px;
    background-color: #116c3a;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    border: 0px;
    margin-bottom: 100px;
    cursor: pointer;
}
.btn:hover {
    background-color: #8caf2c;
}
.router-link-active {
  text-decoration: none;
  color: #fff;
}
a{
  text-decoration: none;
  color: #fff;
}  
</style>