<template>
    <div>
        <div class="header">
            <h3>Nuestros clientes se merecen más</h3>
            <div class="header_img">
                <img src="../assets/img/about1.png" alt="">
            </div>
        </div>
        <div class="main">
            <div class="main_top">
                <p>¿Eres fiel a Prestamia, siempre pagas en el plazo acordado y</p>
                <p>tienes un buen historial crediticio?</p>
                <p>¡Entonces te mereces más!</p>
            </div>
            <div>
                <p class="main_p">¿Cómo subir de nivel?</p>
                <div class="ii">
                    <img class="ii_img" src="../assets/img/about3.svg" alt="">
                        Solicita el máximo crédito disponible
                        <img class="ii_img1" src="../assets/img/about2.svg" alt="">
                        <img class="ii_img" src="../assets/img/about3.svg" alt="">
                        <p>Paga tu préstamo a tiempo</p>
                        <img class="ii_img1" src="../assets/img/about2.svg" alt="">
                        <img class="ii_img" src="../assets/img/about3.svg" alt="">
                        <p>Sube de nivel y aumenta tu límite de crédito</p>
                        
                </div>
                <div class="el">
                    <div class="el_top">
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>300<span>€</span></p>
                            <p>⭐</p>
                        </div>
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>600<span>€</span></p>
                            <p>⭐⭐</p>
                        </div>
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>900<span>€</span></p>
                            <p>⭐⭐⭐</p>
                        </div>
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>1100<span>€</span></p>
                            <p>⭐⭐⭐⭐</p>
                        </div>
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>1400<span>€</span></p>
                            <p>⭐⭐⭐⭐⭐</p>
                        </div>
                    </div>
                    <el-steps :active="active" align-center>
                      <el-step title="Nivel 1" ></el-step>
                      <el-step title="Nivel 2"></el-step>
                      <el-step title="Nivel 3"></el-step>
                      <el-step title="Nivel 4"></el-step>
                      <el-step title="Nivel 5"></el-step>
                    </el-steps>
                    <el-button style="margin-top: 12px;" @click="next">Próximo paso</el-button>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="footer_mid">
                <p>(1) Pide siempre la cantidad  que necesites y haz un uso responsable de nuestro producto.</p>
                <p>(2) El importe máximo ofrecido estará sujeto a la política de aprobación interna de la compañía.</p>
            </div>
        </div>
        <div class="abcss">
        <div class="abcss_middle">
          <div class="abcss_left">
              <h4>¿Necesitas ayuda?</h4>
              <p>¡Resolvemos todas tus dudas!</p>
              <p><a href="https://www.prestamiaes.com" style="color: #fff;text-decoration: underline;">Consulta las preguntas más frecuentes</a></p>
              <p><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiacreditosrapidoss/privacy.html">privacy</a></p>
              <p style="padding: 1vw 0px;"><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiaess/terms.html">Términos y condiciones</a></p>
          </div>
          <div class="abcss_right">
            <div class="tt">
              <!-- <p>+34 643251527</p> -->
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/phone.png" alt=""></span> +34 643251527</p>
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/sms.png" alt=""></span>officialservicio@prestamiaes.com</p>
            </div>
            <div class="jj">
              <p>Horario de atención:</p>
              <p>‍Lunes – Viernes / 8:00 – 21:00</p>
              <p>Sábado / 9:00 – 20:00</p>
              <p>Domingo / 9:00 – 19:00</p>
              <p>Festivos nacionales / 9:00 – 14:00</p>
            </div>
          </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    
    metaInfo : {
    title:'Premiamos tu fidelidad',
    meta:[
    {
        name:'Premiamos tu fidelidad | España',
        content:'Solicita con responsabilidad'
    },
    {
        name:'¿Cómo subir de nivel?',
        content:'Solicita el máximo crédito disponible | Paga tu préstamo a tiempo | Sube de nivel y aumenta tu límite de crédito'
    },
    {
      name:'description',
      content:'Pide siempre la cantidad que necesites y haz un uso responsable de nuestro producto.'
    },
    {
      name:'description',
      content:'El importe máximo ofrecido estará sujeto a la política de aprobación interna de la compañía.'
    },
]
  },
    data() {
      return {
        active: 0
      };
    },

    methods: {
      next() {
        if (this.active++ > 4) this.active = 0;
      }
    }
  }
</script>

<style scoped>
.header {
    margin: auto;
    text-align: center;
    background-color: #f5ffdc;
    height: 25vw;
}
.header > h3 {
    font-size: 40px;
    padding: 50px 0px;
}
.header_img {
    margin: auto;
    width: 50%;
}
.main {
    margin: 250px 0px 50px;
}
.main_top {
    font-size: 25px;

    text-align: center;
}
.main_top > p {
    padding: 5px 0px;
}
.main_p {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin: 50px 0px;
}
.ii {
    display: flex;
    align-items: center;
    width: 60%;
    padding: 5px 10px;
    margin: auto;
    border-radius: 10px;
    background-color: #ebedf2;
}
.ii_img, .ii_img1 {
    width: 17px;
    padding:0px  5px;
}
.el {
    width: 70%;
    margin: 50px auto;
}
.el_top {
    width: 90%;
    text-align: center;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
}
.el_wz > p:nth-child(2) { 
    font-weight: 700;
    font-size: 40px;
    padding-top: 5px;
}
::v-deep .el_wz > p > span {
    font-size: 15px;
}
::v-deep .el-step__head.is-finish {
    color: #116c3a !important;
    border-color: #116c3a !important;
}
::v-deep .el-step__title.is-process {
    color: #116c3a;
}
::v-deep .el-step__title.is-finish {
    color: #116c3a;
}
.footer {
    background-color: #ebedf2;
}
.footer_mid {
    font-size: 14px;
    color: #65696e;
    padding: 50px;
    width: 50%;
    margin: auto;
}
.footer_mid > p {
    
    text-align: left;
    padding: 10px 0px;
}
::v-deep .el-button {
    display: block;
    text-align: center;
    margin: auto;
}
</style> 