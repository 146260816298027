<template>
    <div class="me">
        <div class="main">
            <div class="box">
            <h4>Sobre Prestamia</h4>
            <p>Somos una empresa financiera, líder en la concesión de micropréstamos online. Operamos en España desde el año 2018 y contamos con la fiabilidad de pertenecer a una gran compañía de crédito al consumo como TRES PULPOS S.L UNIPERSONAL Group.</p>
            <p>TRES PULPOS S.L UNIPERSONAL Group, con sede en Mexico City(Mexico), es una multinacional que ofrece servicios financieros en más 11 países. El grupo ha suscrito ya más de 22 millones de microcréditos en el mundo por un valor superior a los 8.000 millones de euros, unos datos que avalan la solvencia de la compañía tanto en España como en el resto de países.
            </p>
            <p>Nuestra filosofía en Prestamia es prestar financiación a nuestros clientes de una forma transparente, rápida y sencilla gracias a la innovación digital.</p>
            <!-- <p>Además, formamos parte de la Asociación Española de Micropréstamos (aemip) y tenemos como objetivo promoverla financiación responsable adoptando los mayores estándares de transparencia y seguridad.</p> -->
        </div>
        <div class="box_img">
            <img src="../assets/img/me1.jpg" alt="">
        </div>
        </div>
        <div class="bcss">
            <p>
                "Nuestra filosofía es prestar financiación de una forma transparente, rápida y sencilla.
            </p>
        </div>
        <div class="ccss">
            <div class="css_mid">
                <div class="ccss_left">
                <h3>22</h3>
                <p>millones de préstamos concedidos</p>
                <h3>8.000</h3>
                <p>millones de euros prestados</p>
                <h3>11</h3>
                <p>países</p>
            </div>
            <div class="ccss_dt">
                <img src="../assets/img/me2.png" alt="">
            </div>
            </div>
        </div>
        <div class="acss">
        <div class="acss_top">
          <p>Estas son las razones para pedir un préstamo sin intereses con Prestamia</p>
        </div>
        <hr>
        <div class="acss_d">
          <div class="acss_d_box">
            <div class="acss_img">
              <img src="../assets/img/acss3.svg" alt="">
            </div>
            <h3>Flexibilidad</h3>
              <p>Retrasa el pago de tu préstamo rápido, amplía la cantidad solicitada o paga con antelación siempre que quieras</p>
          </div>
          <div class="acss_d_box">
            <div class="acss_img">
              <img src="../assets/img/acss2.svg" alt="">
            </div>
            <h3>Transparencia</h3>
              <p>Conoce desde el primer momento cuál es la cantidad a pagar. Nuestros intereses son de los más bajos del mercado</p>
          </div>
          <div class="acss_d_box">
            <div class="acss_img">
              <img src="../assets/img/acss4.svg" alt="">
            </div>
            <h3>Confianza</h3>
              <p>Con la confianza y seguridad de pertenecer al grupo financiero líder en Europa: SUPERESTRELLA UNIDOSS.L</p>
          </div>
        </div>
        </div>
        <div class="dcss">
          <div class="dcss_img">
            <img class="dcss_img_d" src="../assets/img/me3.jpg" alt="">
            <!-- <img class="dcss_img_x" src="../assets/img/me4.jpg" alt=""> -->
          </div>
          <div class="dcss_right">
            <h2>Nuestra responsabilidad social</h2>
            <p>En Prestamia queremos estar siempre cerca de nuestros clientes y también de nuestra sociedad. Por eso creemos en la Responsabilidad Social Corporativa como un eje de nuestra estrategia empresarial.</p>
            <p>Participamos en diferentes acciones para mejorar las condiciones de los más vulnerables.</p>
          </div>
        </div>
        <div class="content">
        <div class="content_top">
          <p>Más de 1.9 millones de usuarios satisfechos</p>
          <div class="content_box">
            <div class="content_box_left">
              <div class="zz">
                <p>4.8/<span style="font-size: 40px;">5</span> </p>

              </div>
            </div>
            <div class="content_box_right">
              <div>⭐⭐⭐⭐⭐</div>
                <p>Valoración media por comentario</p>
            </div>
          </div>
        </div>
        <div class="content_d">

            <div class="content_d_box">
              <div class="vv">
                <img src="../assets/img/content1.png" alt="">
              </div>
              <p>En mi caso no tengo nada malo q decir. Si no todo lo contrario. Es fabuloso. E tenido un apuros y me lo an solucionado rapidisimo. Lo recomiendo a todo el mundo. Gracias.</p>
              </div>
            <div class="content_d_box">
              <div class="vv">
                <img src="../assets/img/content2.png" alt="">
              </div>
              <p>La verdad que es muy fácil rellenar la soiicitud y la atención prestada a través de atención al cliente es excelente. El dinero lo he tenido muy rápido y por ahora estoy muy contento con la empresa.</p>
            </div>
            <div class="content_d_box">
              <div class="vv">
                <img src="../assets/img/content3.png" alt="">
              </div>
              <p>Para mi Prestamia es la primera pagina web de prestamo con buena seguridad, sencillo y rapido. Bastante generosa por no cobrar mas de la cuenta, bueno solo un poco, pero para mi una de las mejores.</p>
            </div>
            <div class="content_d_box">
              <div class="vv">
                <img src="../assets/img/content4.png" alt="">
              </div>
              <p>La atención cara al público es fantástica. algo que mejorar que en vez de a 30 días pudiera ampliarse a 60 sin intereses. Por lo demás muchas gracias por confiar en nosotros sus clientes. Reciban un cordial saludo de.</p>
        </div>
      </div>
        </div>
        <div class="abcss">
        <div class="abcss_middle">
          <div class="abcss_left">
              <h4>¿Necesitas ayuda?</h4>
              <p>¡Resolvemos todas tus dudas!</p>
              <p><a href="https://www.prestamiaes.com" style="color: #fff;text-decoration: underline;">Consulta las preguntas más frecuentes</a></p>
              <p><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiacreditosrapidoss/privacy.html">privacy</a></p>
              <p style="padding: 1vw 0px;"><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiaess/terms.html">Términos y condiciones</a></p>
          </div>
          <div class="abcss_right">
            <div class="tt">
              <!-- <p>+34 643251527</p> -->
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/phone.png" alt=""></span> +34 643251527</p>
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/sms.png" alt=""></span>officialservicio@prestamiaes.com</p>
            </div>
            <div class="jj">
              <p>Horario de atención:</p>
              <p>‍Lunes – Viernes / 8:00 – 21:00</p>
              <p>Sábado / 9:00 – 20:00</p>
              <p>Domingo / 9:00 – 19:00</p>
              <p>Festivos nacionales / 9:00 – 14:00</p>
            </div>
          </div>
        </div>
        </div>
    </div>
</template>

<script>

export default {
  metaInfo : {
    title:'Servicios',
    meta:[
      {
      name:'Servicios | España',
      content:'Somos una empresa financiera, líder en la concesión de micropréstamos online'
    },
    {
      name:'description',
      content:'Nuestra filosofía es prestar financiación de una forma transparente, rápida y sencilla'
    },
    {
      name:'Nuestra responsabilidad social',
      content:'Participamos en diferentes acciones para mejorar las condiciones de los más vulnerables'
    },
    {
      name:'description',
      content:'Más de 1.9 millones de usuarios satisfechos'
    },
  ]
  },
    components:{
    },
    data() {
        return {

        }
    },
    
}
</script>

<style scoped>
@import url(../assets/css/index.css);
hr {
    width: 10%;
    margin: auto;
    background-color: #116c3a;
    height: 3px;
    border-radius: 10px;
}
.me {
    background-color: #ebedf2;
}
.main {
    position: relative;
    display: flex;
    max-width: 1170px;
    margin:  auto;
    padding: 5vw 0px 0px;
}
.box {
    display: flex;
    flex-direction: column;
    width: 55% !important;
}
.box_img {
    position: absolute;
    right: 1px;
    bottom: -60px;
    width: 39.7%;
}
.box > h4 {
    font-size: 40px;
}
.box > p {
    padding: 15px 0px;
    font-size: 17px;
    line-height: 1.5;
}
.bcss {
    background-color: #116c3a;

}
.bcss > p {
    padding: 5vw 40vw 7vw 11vw;
    /* max-width: 1170px; */
    font-size: 40px;
    font-weight: 700;
    margin: auto;
    color: #fff;
    text-align: left;
}
.ccss {

    background-color: #a7cc46;
}
.css_mid {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1170px;
    margin: auto;
    color: #fff;
}
.ccss_left > h3 {
    font-size: 110px;
}
.ccss_left > p {
    font-size: 30px;
}
.dcss{
  display: flex;
  align-items: center;
  width: 1170px;
  margin: auto;
  padding: 100px 0px;
  background-color:#ebedf2
 }
 .dcss_img {
  position: relative;
  display: flex;
 }
 .dcss_img_d {
  width: 500px;
 }
 .dcss_img_x {
  position: absolute;
  bottom: -50px;
  left: -50px;
  width: 200px;
  height: 200px;
 }
 .dcss_right {
  padding-left: 20px;
 }
 .dcss_right > h2 {
  font-size: 40px;
 }
 .dcss_right > p {
  padding: 30px 0px;
  font-size: 20px;
 }
</style>