<template>
    <div>
        <div class="box">
        <router-link to="/NavBox/index">
            <div class="nav_img">
               <img src="../assets/logo.png" alt="">
            Prestamia
            </div>
        </router-link>
        <div class="nav_right">
            <router-link to="/NavBox/me" class="active">Servicios</router-link>
            <router-link to="/NavBox/work" class="active">Cómo funciona</router-link>
            <router-link to="/NavBox/Apply" class="active">Descargar la app</router-link>
            <!-- <router-link to="/NavBox/me" class="active">Blog</router-link> -->
            <router-link to="/NavBox/fidelidad" class="active">Premiamos tu fidelidad</router-link>
            <!-- <router-link to="/NavBox/me" class="active">¿Te ayudamos?</router-link> -->
        </div>
        </div>
        <router-view />
    </div>
</template>
<script >
   export default{
    data() {
        return {

        }
    },
    methods:{

    }
   }
</script>
<style scoped>
img {
    width: 100%;
    height: 100%;
}
.box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0px 1vw;
}
.nav_img {
    display: flex;
    align-items: center;
    width: 100px;
    font-size: 26px;
    font-weight: 700;
    cursor: pointer;
}
.nav_right {
    display: flex;
}
.active {
    padding: 1vw;
    cursor: pointer;
}
.active:hover {
    background-color: #a7cc46;
  border-radius: 15px;
}
.active:visited {
    background-color: #a7cc46;
  border-radius: 15px;
}
.router-link-active {
  text-decoration: none;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
}
a{
  text-decoration: none;
  color: #000;
}  
</style>