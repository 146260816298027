<template>
    
    <div>
        <div class="header">
            <div class="header_mid">
                <div class="header_left">
                <h2>¿Por qué solicitar un crédito al instante con Prestamia? Aquí van algunas razones</h2>
                <hr>
                <p class="header_left_tit"><span><img class="kk" src="../assets/img/about3.svg" alt=""></span>¡El primer crédito online es completamente gratis!</p>
                <p>¡Sólo pagarás el importe que solicites! Tu préstamo al instante sin intereses ni comisiones. Pides 700€ y devuelves 700€. ¡Así de fácil!</p>
                <p class="header_left_tit"><span><img class="kk" src="../assets/img/about3.svg" alt=""></span>¡Es fácil extender la fecha de pago de tu crédito online!</p>
                <p>¡Si no es posible pagar el préstamo dentro del tiempo acordado, hay una solución! Prestamia.es te ofrece la posibilidad de extender la fecha de pago por 7, 14 o 30 días.</p>
                <p class="header_left_tit"><span><img class="kk" src="../assets/img/about3.svg" alt=""></span>¡Tu dinero siempre controlado!</p>
                <p>En Prestamia.es puedes acceder a tu perfil, donde tendrás toda la información sobre tus préstamos online al instante y las opciones más rentables, así como otra información relevante.</p>
                <!-- <button class="btn">SOLICITA TU PRÉSTAMO AL INSTANTE</button> -->
                </div>
                <div class="headar_right">
                    <p><router-link to="/NavBox/work">Consigue tu crédito online en el acto y en tan sólo 3 sencillos pasos</router-link> </p>
                    <p><router-link to="/NavBox/points">¿Cómo ampliar la cantidad del préstamo?</router-link> </p>
                    <p><router-link to="/NavBox/Prestamia">¿Por qué elegir Prestamia?</router-link> </p>
                    <p><router-link to="/NavBox/Use">Solicita con responsabilidad</router-link> </p>
                </div>
            </div>
        </div>
        <div class="abcss">
        <div class="abcss_middle">
          <div class="abcss_left">
              <h4>¿Necesitas ayuda?</h4>
              <p>¡Resolvemos todas tus dudas!</p>
              <p><a href="https://www.prestamiaes.com" style="color: #fff;text-decoration: underline;">Consulta las preguntas más frecuentes</a></p>
              <p><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiacreditosrapidoss/privacy.html">privacy</a></p>
              <p style="padding: 1vw 0px;"><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiaess/terms.html">Términos y condiciones</a></p>
          </div>
          <div class="abcss_right">
            <div class="tt">
              <!-- <p>+34 643251527</p> -->
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/phone.png" alt=""></span> +34 643251527</p>
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/sms.png" alt=""></span>officialservicio@prestamiaes.com</p>
            </div>
            <div class="jj">
              <p>Horario de atención:</p>
              <p>‍Lunes – Viernes / 8:00 – 21:00</p>
              <p>Sábado / 9:00 – 20:00</p>
              <p>Domingo / 9:00 – 19:00</p>
              <p>Festivos nacionales / 9:00 – 14:00</p>
            </div>
          </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    metaInfo : {
    title:'Cómo funciona',
    meta:[
    {
        name:'Cómo funciona | España',
        content:'¡Si no es posible pagar el préstamo dentro del tiempo acordado, hay una solución! Prestamia.es te ofrece la posibilidad de extender la fecha de pago por 7, 14 o 30 días.'
    },
    {
      name:'¿Por qué elegir Prestamia?',
      content:'¡Es fácil extender la fecha de pago de tu crédito online | ¡Tu dinero siempre controlado!'
    },
    {
      name:'description',
      content:'¡Sólo pagarás el importe que solicites! Tu préstamo al instante sin intereses ni comisiones. Pides 700€ y devuelves 700€. ¡Así de fácil!'
    },
]
  },
    
}
</script>

<style scoped>
@import url(../assets/css/index.css);
.header {
    background-color: #f3f4f8;
}
.header_mid {
    display: flex;
    padding:  0px 10%; 
    padding-top: 100px;
}
.header_left {
    padding-right: 50px;
}
.header_left > h2 {
    font-size: 30px;
}
.header_left > hr {
    width: 100px;
    height: 5px;
    margin: 50px 0px;
    border-radius: 10px;
    background-color: #116c3a;
}


.header_left  > p > span {
    margin-right: 10px;
    padding: 0px 5px;
    border-radius: 10px;
    border: 2px solid #a7cc46;
    color: #8caf2c;
}
.header_left  > p {
    margin: 30px 0px;
    font-size: 16px;
    
}
.header_left_tit {
    margin: 60px 0px !important;
    font-weight: 700;
    font-size: 24px !important;
}
.headar_right {
    width: 700px;
    height: 100%;
    background-color: #a7cc46;
    border-radius: 10px;
}
.headar_right > p {
    padding: 20px 50px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    color: #fff;
    font-weight: 700;
}

.headar_right > p:hover {
    background-color: #8caf2c;
    border-radius: 10px;
}
.btn {
    padding: 17px 50px;
    background-color: #116c3a;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    border: 0px;
    margin-bottom: 100px;
    cursor: pointer;
}
.btn:hover {
    background-color: #8caf2c;
}
.router-link-active {
  text-decoration: none;
  color: #fff;
}
a{
  text-decoration: none;
  color: #fff;
}  
.kk {
    width: 17px;
}
</style>