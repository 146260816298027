<template>
    
    <div>
        <div class="header">
            <div class="header_mid">
                <div class="header_left">
                <h2>Pide tus microcréditos sin nómina con responsabilidad ¡Piénsalo dos veces!</h2>
                <hr>
                <p>Los préstamos rápidos de Prestamia son una herramienta de financiación a corto plazo pensada para los imprevistos del día a día. Al ser un servicio fácil y rápido, los préstamos al momento deben ser solicitados de manera responsable y cauta.</p>
                <p class="header_left_tit"><span><img class="kk" src="../assets/img/about3.svg" alt=""></span>Un préstamo Prestamia es adecuado para ti si:</p>
                <p>— Necesitas dinero rápido extra para un apuro hasta el día del cobro de nómina.</p>
                <p>— Tienes que hacer una compra no planificada o pagar una factura inesperada.</p>
                <p>— Tienes ingresos regulares y eres capaz de pagar el préstamo dentro de 30 días.</p>
                <p class="header_left_tit"><span style="border: 2px solid red;"><img class="kkk" src="../assets/img/use1.svg" alt=""></span>No utilices los mini créditos de Prestamia para:</p>
                <p>— Pagar deudas, por ejemplo: la hipoteca del hogar o cualquier otro tipo de préstamo.</p>
                <p>— Para resolver problemas financieros a largo plazo.</p>
                <p>— Para pagar por un consumo irresponsable.</p>
                <p class="header_left_tit"><span><img class="kk" src="../assets/img/about3.svg" alt=""></span>Antes de pedir un préstamo online:</p>
                <p>— Piensa detenidamente si realmente necesitas el dinero o no.</p>
                <p>— Ten en cuenta si serás capaz de devolver el dinero prestado en el tiempo establecido.</p>
                <p>— Asegúrate de que los reembolsos del crédito no superen el 40% de tus ingresos mensuales.</p>
                <p>— Pide prestado sólo la cantidad de dinero que sea absolutamente necesaria.</p>
                <p class="header_left_tit"><span ><img class="kk" src="../assets/img/about3.svg" alt=""></span>Si has tomado la decisión de pedir un préstamo a corto plazo:</p>
                <p>—Evalúa cuidadosamente si la tasa de porcentaje (tasa de comisión) que se ofrece es la adecuada para tí.</p>
                <p>— Lee cuidadosamente y familiarízate con los términos del contrato.</p>
                <p>— Lee cuidadosamente y familiarízate con la información preregistro.</p>
                <p>— Presta atención a tu presupuesto mensual para pagar el préstamo en la fecha especificada.</p>
                <p class="header_left_tit"><span style="border: 2px solid red;color: red;padding: 3px 14px;"> ! </span>Si estás en un apuro financiero y no eres capaz de pagar la deuda:</p>
                <p>— De ninguna manera solicites un préstamo a otras entidades de crédito con el fin de pagar la deuda actual.</p>
                <p>— Ponte en contacto con nuestro servicio de atención al cliente con el fin de encontrar la mejor solución para tu problema.</p>
                <p>— No pospongas la resolución de problemas: cuanto más tiempo pase, más difícil será resolver el problema.</p>
                <p>— Revisa tu presupuesto mensual, ya que podría existir la posibilidad de reducir o quitar algunos de los gastos.</p>

                <div style="padding: 60px 0px 50px;font-size: 20px;">
                    <p>Los principios que rigen el compromiso de 4Finance Spain Financial Services con sus clientes son: la ética y la responsabilidad. Para garantizar a nuestros clientes el cumplimiento de los mismos, seguimos un código de buenas prácticas. </p>
                </div>
                <!-- <button class="btn">SOLICITA TU PRÉSTAMO AL INSTANTE</button> -->
                </div>
                <div class="headar_right">
                    <p><router-link to="/NavBox/work">Consigue tu crédito online en el acto y en tan sólo 3 sencillos pasos</router-link> </p>
                    <p><router-link to="/NavBox/points">¿Cómo ampliar la cantidad del préstamo?</router-link> </p>
                    <p><router-link to="/NavBox/Prestamia">¿Por qué elegir Prestamia?</router-link> </p>
                    <p><router-link to="/NavBox/Use">Solicita con responsabilidad</router-link> </p>
                </div>
            </div>
        </div>
        <div class="abcss">
        <div class="abcss_middle">
          <div class="abcss_left">
              <h4>¿Necesitas ayuda?</h4>
              <p>¡Resolvemos todas tus dudas!</p>
              <p><a href="https://www.prestamiaes.com" style="color: #fff;text-decoration: underline;">Consulta las preguntas más frecuentes</a></p>
              <p><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiacreditosrapidoss/privacy.html">privacy</a></p>
              <p style="padding: 1vw 0px;"><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiaess/terms.html">Términos y condiciones</a></p>
          </div>
          <div class="abcss_right">
            <div class="tt">
              <!-- <p>+34 643251527</p> -->
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/phone.png" alt=""></span> +34 643251527</p>
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/sms.png" alt=""></span>officialservicio@prestamiaes.com</p>
            </div>
            <div class="jj">
              <p>Horario de atención:</p>
              <p>‍Lunes – Viernes / 8:00 – 21:00</p>
              <p>Sábado / 9:00 – 20:00</p>
              <p>Domingo / 9:00 – 19:00</p>
              <p>Festivos nacionales / 9:00 – 14:00</p>
            </div>
          </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    metaInfo : {
    title:'Cómo funciona',
    meta:[
    {
        name:'Cómo funciona | España',
        content:'Solicita con responsabilidad'
    },
    {
      name:'description',
      content:'Pide tus microcréditos sin nómina con responsabilidad ¡Piénsalo dos veces!'
    },
    {
      name:'description',
      content:'Pide tus microcréditos sin nómina con responsabilidad ¡Piénsalo dos veces!'
    },
]
  },
}
</script>

<style scoped>
@import url(../assets/css/index.css);
.header {
    background-color: #f3f4f8;
}
.header_mid {
    display: flex;
    padding:  0px 10%; 
    padding-top: 100px;
}
.header_left {
    padding-right: 50px;
}
.header_left > h2 {
    font-size: 30px;
}
.header_left > hr {
    width: 100px;
    height: 5px;
    margin: 50px 0px;
    border-radius: 10px;
    background-color: #116c3a;
}


.header_left  > p > span {
    margin-right: 10px;
    padding: 4px 10px;
    border-radius: 10px;
    border: 2px solid #a7cc46;
    color: #8caf2c;
}
.header_left  > p {
    margin: 30px 0px;
    font-size: 16px;
    
}
.header_left_tit {
    display: flex;
    margin: 60px 0px !important;
    font-weight: 700;
    font-size: 24px !important;
    align-items: center;
}
.headar_right {
    width: 700px;
    height: 100%;
    background-color: #a7cc46;
    border-radius: 10px;
}
.headar_right > p {
    padding: 20px 50px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    color: #fff;
    font-weight: 700;
}

.headar_right > p:hover {
    background-color: #8caf2c;
    border-radius: 10px;
}
.btn {
    padding: 17px 50px;
    background-color: #116c3a;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    border: 0px;
    margin-bottom: 100px;
    cursor: pointer;
}
.btn:hover {
    background-color: #8caf2c;
}
.router-link-active {
  text-decoration: none;
  color: #fff;
}
a{
  text-decoration: none;
  color: #fff;
}  
.kk {
    width: 17px;
}
.kkk {
    width: 15px;
    
}
</style>