var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"main"},[_vm._m(1),_c('div',[_c('p',{staticClass:"main_p"},[_vm._v("¿Cómo subir de nivel?")]),_vm._m(2),_c('div',{staticClass:"el"},[_vm._m(3),_c('el-steps',{attrs:{"active":_vm.active,"align-center":""}},[_c('el-step',{attrs:{"title":"Nivel 1"}}),_c('el-step',{attrs:{"title":"Nivel 2"}}),_c('el-step',{attrs:{"title":"Nivel 3"}}),_c('el-step',{attrs:{"title":"Nivel 4"}}),_c('el-step',{attrs:{"title":"Nivel 5"}})],1),_c('el-button',{staticStyle:{"margin-top":"12px"},on:{"click":_vm.next}},[_vm._v("Próximo paso")])],1)])]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('h3',[_vm._v("Nuestros clientes se merecen más")]),_c('div',{staticClass:"header_img"},[_c('img',{attrs:{"src":require("../assets/img/about1.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_top"},[_c('p',[_vm._v("¿Eres fiel a Prestamia, siempre pagas en el plazo acordado y")]),_c('p',[_vm._v("tienes un buen historial crediticio?")]),_c('p',[_vm._v("¡Entonces te mereces más!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ii"},[_c('img',{staticClass:"ii_img",attrs:{"src":require("../assets/img/about3.svg"),"alt":""}}),_vm._v(" Solicita el máximo crédito disponible "),_c('img',{staticClass:"ii_img1",attrs:{"src":require("../assets/img/about2.svg"),"alt":""}}),_c('img',{staticClass:"ii_img",attrs:{"src":require("../assets/img/about3.svg"),"alt":""}}),_c('p',[_vm._v("Paga tu préstamo a tiempo")]),_c('img',{staticClass:"ii_img1",attrs:{"src":require("../assets/img/about2.svg"),"alt":""}}),_c('img',{staticClass:"ii_img",attrs:{"src":require("../assets/img/about3.svg"),"alt":""}}),_c('p',[_vm._v("Sube de nivel y aumenta tu límite de crédito")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"el_top"},[_c('div',{staticClass:"el_wz"},[_c('p',[_vm._v("Consigue hasta")]),_c('p',[_vm._v("300"),_c('span',[_vm._v("€")])]),_c('p',[_vm._v("⭐")])]),_c('div',{staticClass:"el_wz"},[_c('p',[_vm._v("Consigue hasta")]),_c('p',[_vm._v("600"),_c('span',[_vm._v("€")])]),_c('p',[_vm._v("⭐⭐")])]),_c('div',{staticClass:"el_wz"},[_c('p',[_vm._v("Consigue hasta")]),_c('p',[_vm._v("900"),_c('span',[_vm._v("€")])]),_c('p',[_vm._v("⭐⭐⭐")])]),_c('div',{staticClass:"el_wz"},[_c('p',[_vm._v("Consigue hasta")]),_c('p',[_vm._v("1100"),_c('span',[_vm._v("€")])]),_c('p',[_vm._v("⭐⭐⭐⭐")])]),_c('div',{staticClass:"el_wz"},[_c('p',[_vm._v("Consigue hasta")]),_c('p',[_vm._v("1400"),_c('span',[_vm._v("€")])]),_c('p',[_vm._v("⭐⭐⭐⭐⭐")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer_mid"},[_c('p',[_vm._v("(1) Pide siempre la cantidad que necesites y haz un uso responsable de nuestro producto.")]),_c('p',[_vm._v("(2) El importe máximo ofrecido estará sujeto a la política de aprobación interna de la compañía.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abcss"},[_c('div',{staticClass:"abcss_middle"},[_c('div',{staticClass:"abcss_left"},[_c('h4',[_vm._v("¿Necesitas ayuda?")]),_c('p',[_vm._v("¡Resolvemos todas tus dudas!")]),_c('p',[_c('a',{staticStyle:{"color":"#fff","text-decoration":"underline"},attrs:{"href":"https://www.prestamiaes.com"}},[_vm._v("Consulta las preguntas más frecuentes")])]),_c('p',[_c('a',{staticStyle:{"color":"#fff","font-size":"17px","text-decoration":"underline !important"},attrs:{"href":"https://www.prestamiaes.com/prestamiacreditosrapidoss/privacy.html"}},[_vm._v("privacy")])]),_c('p',{staticStyle:{"padding":"1vw 0px"}},[_c('a',{staticStyle:{"color":"#fff","font-size":"17px","text-decoration":"underline !important"},attrs:{"href":"https://www.prestamiaes.com/prestamiaess/terms.html"}},[_vm._v("Términos y condiciones")])])]),_c('div',{staticClass:"abcss_right"},[_c('div',{staticClass:"tt"},[_c('p',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticStyle:{"height":"70%"}},[_c('img',{staticStyle:{"width":"25px","height":"25px"},attrs:{"src":require("../assets/img/phone.png"),"alt":""}})]),_vm._v(" +34 643251527")]),_c('p',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticStyle:{"height":"70%"}},[_c('img',{staticStyle:{"width":"25px","height":"25px"},attrs:{"src":require("../assets/img/sms.png"),"alt":""}})]),_vm._v("officialservicio@prestamiaes.com")])]),_c('div',{staticClass:"jj"},[_c('p',[_vm._v("Horario de atención:")]),_c('p',[_vm._v("‍Lunes – Viernes / 8:00 – 21:00")]),_c('p',[_vm._v("Sábado / 9:00 – 20:00")]),_c('p',[_vm._v("Domingo / 9:00 – 19:00")]),_c('p',[_vm._v("Festivos nacionales / 9:00 – 14:00")])])])])])
}]

export { render, staticRenderFns }