<template>
    <div>
        <div class="smacss">
        <div class="smacss_middle">
          <div class="smacss_left">
          <h3>¡Descárgate la</h3>
          <p style="font-size: 55px;"> app de Prestamia!</p>
          <h4>Podrás hacer todas tus gestiones</h4>
          <p style="color: #fff;font-size: 40px;">en solo unos clics</p>
          <p class="dg1">Gestiona tu préstamo desde donde tú quieras</p>
          <p class="dg1">De forma fácil, rápida y segura</p>
          <p class="dg1">Conoce en todo momento el estado de tu préstamo</p>
          <div class="smacss_xz">
            <!-- <div style="margin-left: 1vw;cursor: pointer;width: 60px;">
                <img src="../assets/logo.png" alt="">
            </div> -->
            <!-- <div class="smacss_btn">
              <a href="https://play.google.com/store/apps/details?id=com.abanca.santander.libertex.vivus.revolut.caixabank.icmarkets.waylet.prestamia&hl=es-419&gl=es"><img src="../assets/img/google.svg" alt=""></a>
            </div> -->
<!--  -->

<!--  -->
          </div>
        </div>
        <div class="smacss_right">

          <img src="../assets/img/sj.png" alt="">
        </div>
        </div>
        </div>
        <div class="main">
          <div class="main_mid">
            <div class="main_top">
            <h3>Las ventajas de instalar</h3>
            <h3>la nueva app de Prestamia</h3>
          </div>
          <div class="main_d">
            <div class="main_n">
              <div class="main_box">
                <div class="main_img">
                  <img src="../assets/img/apply1.svg" alt="">
                </div>
                <div class="main_wz">
                  <h2>Rapidez</h2>
                  <p>Ahora podrás hacer todas tus gestiones en solo unos clics</p>
                </div>
              </div>
            </div>
            <div class="main_n">
              <div class="main_box">
                <div class="main_img" >
                  <img src="../assets/img/apply2.png" alt="">
                </div>
                <div class="main_wz">
                  <h2>Seguridad</h2>
                  <p>Navega con toda la confianza gracias a los datos encriptados</p>
                </div>
              </div>
            </div>
            <div class="main_n">
              <div class="main_box">
                <div class="main_img">
                  <img src="../assets/img/apply3.svg" alt="">
                </div>
                <div class="main_wz">
                  <h2>Sencillez</h2>
                  <p>Esta nueva versión es aún más fácil e intuitiva  </p>
                </div>
              </div>
            </div>
            <div class="main_n">
              <div class="main_box">
                <div class="main_img">
                  <img src="../assets/img/apply4.svg" alt="">
                </div>
                <div class="main_wz">
                  <h2>Organización</h2>
                  <p>Todas tus promociones, pagos y alertas en el mismo lugar</p>
                </div>
              </div>
            </div>
            <div class="main_n">
              <div class="main_box">
                <div class="main_img">
                  <img src="../assets/img/apply5.svg" alt="">
                </div>
                <div class="main_wz">
                  <h2>Donde quieras</h2>
                  <p>Podrás pedir un préstamo cuando, como y donde quieras</p>
                </div>
              </div>
            </div>
            <div class="main_n">
              <div class="main_box">
                <div class="main_img">
                  <img src="../assets/img/apply6.svg" alt="">
                </div>
                <div class="main_wz">
                  <h2>Información</h2>
                  <p>Consulta todas tus dudas en solo unos segundos  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div class="smacss A_sma">
        <div class="smacss_middle">
          <div class="smacss_right A_sms_right">
               <img src="../assets/img/qaq.png" alt="">
          </div>
          <div class="smacss_left  A_sms_left">
          <h3>Todo lo que podrás hacer desde tu aplicación</h3>
          <p class="dg1">Solicitar más dinero hasta alcanzar tu límite de crédito disponible</p>
          <p class="dg1">Conoce el estado de tu préstamo: podrás consultar si tu crédito ha sido aprobado y la fecha de vencimiento</p>
          <p class="dg1">Aplazar el pago de tu préstamo hasta el mes siguiente</p>
          <div class="smacss_xz">
<!--  -->

<!--  -->
          </div>
        </div>
        </div>
        </div>
        <div class="contion">
          <div class="contion_mid">
            <div class="contion_top">
            <p>¿Por qué a nuestros clientes les encanta la nueva app?</p>
          </div>
          <div class="contion_d">
            <div class="active">
              <h3>Una Salvación!</h3>
              <p>La verdad que es la única entidad financiera que me ha ayudado mucho!</p>
            </div>
            <div class="active">
              <h3>Genial!</h3>
              <p>La App es perfecta, funciona para lo que está creada y es mucho mejor que tener que meterte en la página web, eso si, alguna vez da fallo a la hora de devolver el préstamo y hacer el pago, tengo que hacerlo desde la página web porque desde la App me dice que hay un error y no me deja devolverlo.</p>
            </div>
            <div class="active">
              <h3>Excelente</h3>
              <p>Préstamo inmediato y muy fácil de hacerlo le doy 5 estrellas</p>
            </div>
          </div>
        </div>
        </div>
        <div class=" B_sma" style="background-color: #2c3138 !important;">
        <div class="smacss_middle">
          <div class="smacss_right B_sms_right ">
               <img src="../assets/img/vvv.png" alt="">
          </div>
          <div class="smacss_left  B_sms_left">
          <h3>¡Comienza a usar ya la nueva app de Prestamia!</h3>
          <p>Empieza a disfrutar de todas las ventajas</p>
          <p>de la aplicación en tu móvil. </p>
          <p>¡Descárgatela!</p>
          <div class="smacss_btn B_btn" >
              <!-- <a href="https://play.google.com/store/apps/details?id=com.abanca.santander.libertex.vivus.revolut.caixabank.icmarkets.waylet.prestamia&hl=es-419&gl=es"><img src="../assets/img/google.svg" alt=""></a> -->
            </div>
        </div>
        </div>
        </div>
        <div class="abcss">
        <div class="abcss_middle">
          <div class="abcss_left">
              <h4>¿Necesitas ayuda?</h4>
              <p>¡Resolvemos todas tus dudas!</p>
              <p><a href="https://www.prestamiaes.com" style="color: #fff;text-decoration: underline;">Consulta las preguntas más frecuentes</a></p>
              <p><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiacreditosrapidoss/privacy.html">privacy</a></p>
              <p style="padding: 1vw 0px;"><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiaess/terms.html">Términos y condiciones</a></p>
            </div>
          <div class="abcss_right">
            <div class="tt">
              <!-- <p>+34 643251527</p> -->
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/phone.png" alt=""></span> +34 643251527</p>
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/sms.png" alt=""></span>officialservicio@prestamiaes.com</p>
            </div>
            <div class="jj">
              <p>Horario de atención:</p>
              <p>‍Lunes – Viernes / 8:00 – 21:00</p>
              <p>Sábado / 9:00 – 20:00</p>
              <p>Domingo / 9:00 – 19:00</p>
              <p>Festivos nacionales / 9:00 – 14:00</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  
  metaInfo : {
    title:'Descargar la app',
    meta:[
      {
      name:'Descargar la app | España',
      content:'¡Descárgate la app de Prestamia!'
    },
    {
      name:'Las ventajas de instalar la nueva app de Prestamia',
      content:'Rapidez | Seguridad | Sencillez | Organización | Donde quieras | Información'
    },
    {
      name:'description',
      content:'Solicitar más dinero hasta alcanzar tu límite de crédito disponible'
    },
    {
      name:'description',
      content:'Conoce el estado de tu préstamo: podrás consultar si tu crédito ha sido aprobado y la fecha de vencimiento'
    },
    {
      name:'description',
      content:'Aplazar el pago de tu préstamo hasta el mes siguiente'
    },
  ]
  },
}
</script>

<style scoped>
@import url(../assets/css/index.css);
.smacss_right {
    width: 300px;
}
.smacss_left > h3 {
    font-size: 60px;
}
.smacss_left > h4 {
    color: #116c3a;
}
.main {
  height: 850px;
  background-image: url(../assets/img/apply7.jpg) ;
  background-size: 100%;
  background-repeat: no-repeat;

}
.main_mid {
  width: 1170px;
  margin: auto;
  padding: 50px 0px;
}
.main_top {
  font-size: 30px;
}
.main_n {
  display: flex;
}
.main_d {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px 0px;
}
.main_box {
  display: flex;
  align-items: center;
  width: 300px;
  margin: 20px 0px;
}
.main_img {
  display: flex;
  width: 70px;
  height: 55px;
}
.main_wz {
  margin-left: 15px;
}
.main_wz > p  {
  padding: 7px 0px;
  line-height: 1,4;
}
.A_sma {
  padding: 5vw 0px 0px;
}
.A_sms_left {
  width: 50%;
  color: #fff !important;
}
.A_sms_left > h3 , .A_sms_left > p {
  color: #fff !important;
}
.A_sms_left > h3 {
  padding-bottom: 40px;
}
.A_sms_left > p {
  font-size: 18px;
  line-height: 1.5;
  padding: 30px 0px 30px 3vw;
}
.A_sms_right {
  width: 400px;
}
.active {
  background-color: #e3e2e2;
  border-radius: 10px;
  background-repeat:no-repeat;
  background-size: cover;
  width: 250px;
  padding: 50px;
}
.active > h3 {
  font-size: 30px;
  padding-bottom: 10px;
}
.active > p {
  font-size: 20px;
}

.contion_mid {
  width: 1170px;
  margin: 50px auto;
}
.contion_top {
  text-align: center;
  font-size: 40px;
}
.contion_top >p {
  padding:  70px 20%; 
  font-weight: 700;
}
.contion_d {
  display: flex;
  justify-content: space-between;
}

.B_sma {
  margin-top: 100px;
  height: 30vw;
  margin-bottom: 50px;
  padding: 5vw 0px 0px;
}
.B_sms_left {
  width: 50%;
  color: #fff !important;
}
.B_sms_left > h3 , .B_sms_left > p {
  color: #fff !important;
}
.B_sms_left > h3 {
  padding-bottom: 20px;
  font-size: 40px;
}
.B_sms_left > p {
  font-size: 18px;
  line-height: 1.2;
  padding: 5px 0px;
}
.B_sms_right {
  width: 270px;
  margin-bottom: 100px;
}
.B_btn{
  margin-left: 0px;
}
</style>