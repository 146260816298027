<template>
  <div>
    <div class="nav">
      <div class="nav_middle">
        <div class="nav_left">
          <p>PIDE TU PRÉSTAMO RÁPIDO Y SIÉNTETE Prestamia!</p>
          <p>Tu primer crédito, de hasta 700€, sin intereses ni comisiones.</p>
          <div class="nav_xx">
            <p><span class="dg"><img src="../assets/img/dg.svg" alt=""></span>Y si ya eres cliente de Prestamia, te prestamos hasta 1400€. Pídelos en tu área personal.</p>
            <p><span class="dg"><img src="../assets/img/dg.svg" alt=""></span>Te transferimos el dinero en menos de 15 minutos.</p>
            <p><span class="dg"><img src="../assets/img/dg.svg" alt=""></span>Tu préstamo online rápido, sin aval y sin papeleos.</p>
            <p><span class="dg"><img src="../assets/img/dg.svg" alt=""></span>Sin letra pequeña, con total transparencia y seguridad.</p>
          </div>
        </div>
        <div class="nav_right">
          <div class="nav_right_top">
            <div class="nav_right_top_xx">

              <div>
                <p style="padding: 1vw;">Calcula tu crédito online</p>
              <p  style="font-size: 19px;">¿Cuánto dinero necesitas?<span style="float: right;">{{ value }} €</span></p>
              <div class="block">
                <el-slider v-model="value" :min="50" :max="700" :show-tooltip="false"></el-slider>
                <p style="line-height: 1vw;"><span style="font-size: 12px;" >50€</span><span style="float: right;font-size: 12px;">700€</span></p>
              </div>   
            </div>
          

             <div>
              <p style="font-size: 19px;padding-top: 1vw;">¿En cuántos días quieres devolverlo?<span style="float: right;padding-left: 1vw;">{{ value1 }} días</span></p>
              <div class="block">
                <el-slider v-model="value1" @input="a"  :min="7" :max="30" :show-tooltip="false"></el-slider>
                <p><span style="font-size: 12px;">7</span><span style="float: right;font-size: 12px;">30</span></p>
              </div> 
             </div>
            
            </div>
          </div>
          <div class="nav_right_button">
            <div class="nav_right_button_xx">
              <p style="border-bottom: 1px solid #ccc;padding: 5px;">Importe solicitado <span style="float: right;">{{ value }} €</span></p>
              <p style="border-bottom: 1px solid #ccc;padding: 5px;">Intereses <span style="float: right;">0 €</span></p>
              <p style="border-bottom: 1px solid #ccc;padding: 5px;">Total a devolver <span style="float: right;">{{ value }} €</span></p>
              <p style="padding: 5px; font-weight: 700;">Fecha de devolución<span style="float: right;">{{ time }}</span></p>
              <div>
                <!-- <button><a href="https://play.google.com/store/apps/details?id=com.abanca.santander.libertex.vivus.revolut.caixabank.icmarkets.waylet.prestamia&hl=es-419&gl=es">Solicita tu primer préstamo</a></button> -->
                <!-- <a href="https://play.google.com/store/apps/details?id=com.abanca.santander.libertex.vivus.revolut.caixabank.icmarkets.waylet.prestamia&hl=es-419&gl=es" style="text-decoration:underline;">INFORMACIÓN SOBRE EL PRÉSTAMO</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="main">
        <div class="main_top">
          <p>Cómo solicitar un crédito rápido</p>
          <p>Solicita un préstamo online sin intereses con Prestamia es sencillo y 100% seguro</p>
          <hr>
        </div>
        <div class="main_nr">
          <div class="main_nr_box">
            <div class="main_img">
              <img src="../assets/img/main_nr1.svg" alt="">
            </div>
            <h4>Solicita tu Crédito rápido</h4>
            <p style="padding: 1vw 0px;font-size: 12px;">Consigue tu crédito al instante es muy fácil, sólo necesitas tres requisitos:</p>
            <div class="GG">
              <p><strong class="gg">—</strong> Ser español o disponer de tarjeta de residencia</p>
              <p><strong class="gg">—</strong> Ser mayor de edad</p>
              <p><strong class="gg">—</strong> Ser titular de una cuenta bancaria en España</p>
            </div>
          </div>
          <div class="main_nr_box">
            <div class="main_img">
              <img src="../assets/img/main_nr2.svg" alt="">
            </div>
            <h4>Verifica tu identidad</h4>
            <p style="padding: 1vw 0px;font-size: 12px;">Utilizamos las mejores y más seguras herramientas para proteger tus datos:</p>
            <div class="GG">
              <p><strong class="gg">—</strong> Elige tu banco</p>
              <p><strong class="gg">—</strong> Identifícate</p>
            </div>
          </div>
          <div class="main_nr_box">
            <div class="main_img">
              <img src="../assets/img/main_nr3.svg" alt="">
            </div>
            <h4>Ya tienes tu minicrédito</h4>
            <p style="padding: 1vw 0px;font-size: 12px;">Gestiona tu crédito online en tu área de cliente de manera fácil y sencilla:</p>
            <div class="GG">
              <p><strong class="gg">—</strong> Amplía el plazo de tu préstamo rápido</p>
              <p><strong class="gg">—</strong> Amplía la cantidad solicitada</p>
              <p><strong class="gg">—</strong> Programa el pago automáticamente</p>
            </div>
          </div>
        </div>
      </div>
      <div class="ben">
        <div class="ben_top">
          <div class="ben_top_img">
            <img src="../assets/img/nuevo.svg" alt="">
          </div>
          <h3>Premiamos tu fidelidad</h3>
          <p>Nuestros clientes se merecen más</p>
        </div>
        <div>
                <div class="el">
                    <div class="el_top">
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>300<span>€</span></p>
                            <p>⭐</p>
                        </div>
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>600<span>€</span></p>
                            <p>⭐⭐</p>
                        </div>
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>900<span>€</span></p>
                            <p>⭐⭐⭐</p>
                        </div>
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>1100<span>€</span></p>
                            <p>⭐⭐⭐⭐</p>
                        </div>
                        <div class="el_wz">
                            <p>Consigue hasta</p>
                            <p>1400<span>€</span></p>
                            <p>⭐⭐⭐⭐⭐</p>
                        </div>
                    </div>
                    <el-steps :active="active" align-center>
                      <el-step title="Nivel 1" ></el-step>
                      <el-step title="Nivel 2"></el-step>
                      <el-step title="Nivel 3"></el-step>
                      <el-step title="Nivel 4"></el-step>
                      <el-step title="Nivel 5"></el-step>
                    </el-steps>
                    <el-button style="margin-top: 12px;" @click="next">Próximo paso</el-button>
                </div>
            </div>
      </div>
      <div class="content">
        <div class="content_top">
          <p>Más de 1.9 millones de usuarios satisfechos</p>
          <div class="content_box">
            <div class="content_box_left">
              <div class="zz">
                <p>4.8/<span style="font-size: 40px;">5</span> </p>

              </div>
            </div>
            <div class="content_box_right">
              <div>⭐⭐⭐⭐⭐</div>
                <p>Valoración media por comentario</p>
            </div>
          </div>
        </div>
        <div class="content_d">

            <div class="content_d_box">
              <div class="vv">
                <img src="../assets/img/content1.png" alt="">
              </div>
              <p>En mi caso no tengo nada malo q decir. Si no todo lo contrario. Es fabuloso. E tenido un apuros y me lo an solucionado rapidisimo. Lo recomiendo a todo el mundo. Gracias.</p>
              </div>
            <div class="content_d_box">
              <div class="vv">
                <img src="../assets/img/content2.png" alt="">
              </div>
              <p>La verdad que es muy fácil rellenar la soiicitud y la atención prestada a través de atención al cliente es excelente. El dinero lo he tenido muy rápido y por ahora estoy muy contento con la empresa.</p>
            </div>
            <div class="content_d_box">
              <div class="vv">
                <img src="../assets/img/content3.png" alt="">
              </div>
              <p>Para mi Prestamia es la primera pagina web de prestamo con buena seguridad, sencillo y rapido. Bastante generosa por no cobrar mas de la cuenta, bueno solo un poco, pero para mi una de las mejores.</p>
            </div>
            <div class="content_d_box">
              <div class="vv">
                <img src="../assets/img/content4.png" alt="">
              </div>
              <p>La atención cara al público es fantástica. algo que mejorar que en vez de a 30 días pudiera ampliarse a 60 sin intereses. Por lo demás muchas gracias por confiar en nosotros sus clientes. Reciban un cordial saludo de.</p>
        </div>
      </div>
      </div>
      <div class="acss">
        <div class="acss_top">
          <p>Estas son las razones para pedir un préstamo sin intereses con Prestamia</p>
        </div>
        <hr>
        <div class="acss_d">
          <div class="acss_d_box">
            <div class="acss_img">
              <img src="../assets/img/acss1.svg" alt="">
            </div>
            <h3>Seguridad</h3>
              <p>Utilizamos las más avanzadas herramientas de encriptación de datos para asegurar tu privacidad</p>
          </div>
          <div class="acss_d_box">
            <div class="acss_img">
              <img src="../assets/img/acss2.svg" alt="">
            </div>
            <h3>Flexibilidad</h3>
              <p>Retrasa el pago de tu préstamo rápido, amplía la cantidad solicitada o paga con antelación siempre que quieras</p>
          </div>
          <div class="acss_d_box">
            <div class="acss_img">
              <img src="../assets/img/acss3.svg" alt="">
            </div>
            <h3>Transparencia</h3>
              <p>Conoce desde el primer momento cuál es la cantidad a pagar. Nuestros intereses son de los más bajos del mercado</p>
          </div>
          <div class="acss_d_box">
            <div class="acss_img">
              <img src="../assets/img/acss4.svg" alt="">
            </div>
            <h3>Confianza</h3>
              <p>Con la confianza y seguridad de pertenecer al grupo financiero líder en Europa: SUPERESTRELLA UNIDOSS.L</p>
          </div>
        </div>
      </div>
      <div class="oocss">
        <h2>¿Quieres tu primer préstamo ya?</h2>
        <p>Consigue tus primeros 700€ sin intereses ni comisiones, y devuélvelos en 30 días</p>
        <button><a href="https://www.prestamiaes.com">Solicita tu crédito ahora</a></button>
      </div>
      <div class="smacss">
        <div class="smacss_middle">
          <div class="smacss_left">
          <h3>¡Descárgate </h3>
          <p style="font-size: 45px;">la app de Prestamia!</p>
          <h4>Podrás hacer todas tus gestiones</h4>
          <p >en solo unos clics</p>
          <p class="dg1">Gestiona tu préstamo desde donde tú quieras</p>
          <p class="dg1">De forma fácil, rápida y segura</p>
          <p class="dg1">Conoce en todo momento el estado de tu préstamo</p>
          <div class="smacss_xz">
            <!-- <div class="smacss_btn">
              <a href="https://play.google.com/store/apps/details?id=com.abanca.santander.libertex.vivus.revolut.caixabank.icmarkets.waylet.prestamia&hl=es-419&gl=es"><img src="../assets/img/google.svg" alt=""></a>
            </div> -->
<!--  -->

<!--  -->
          </div>
        </div>
        <div class="smacss_right">
          <img src="../assets/img/sj.png" alt="">
        </div>
        </div>
      </div>
      <div>
        <img style="display: block;" src="../assets/img/liucheng.png" alt="">
      </div>
      <div class="section">
        <div class="section_top">
          <p>Preguntas Frecuentes</p>
        </div>
        <div class="section_d">
          <div class="section_d_middle">



            <el-collapse v-model="activeNames" @change="handleChange">
              <div class="active">
                <el-collapse-item title="¿Qué es Prestamia?" name="1">
                <div>Somos una entidad especializada en financiación que concede prestamos rapidos. Actualmente, somos de las más importantes y populares a nivel nacional. Comenzamos a operar en España en 2013 y formamos parte de SUPERESTRELLA UNIDOSS.L Group, una de las empresas especializadas en prestamos rapidos más importantes a nivel mundial, con presencia en 10 países. Estamos especializados en préstamos rápidos: hasta 700€ si eres cliente nuevo y hasta 1.400€ para antiguos clientes, que deben devolverse en un plazo máximo de 30 días, aunque el cliente puede ampliar el plazo de devolución de su préstamo a través de las extensiones.</div>
              </el-collapse-item>
              <el-collapse-item title="¿Cuáles son las ventajas de los préstamos Prestamia?" name="2">
                <div><p>Tienes un gran número de ventajas por ser cliente de Prestamia:</p>
                  <li>El primer préstamo de 700 € es gratis. Pides 700 euros y devuelves 700, sin intereses ni comisiones.</li>
                  <li>Recibe tu dinero en 15 minutos. Desde que aprobamos tu préstamo, te transferimos el dinero en un máximo de 15 minutos, por lo que el dinero estará en tu cuenta antes de 48 horas (dependiendo el banco).</li>
                  <li>Pedir créditos rápidos con Prestamia es sencillo y puedes hacerlo directamente a través de nuestra web o en nuestra app, en Android o iPhone.</li>
                  <li>Mantenemos la privacidad de tus datos a través del uso de los sistemas más avanzados de encriptación.</li>
                  <li>Detrás de los préstamos rápidos de Prestamia está el grupo financiero líder en Europa SUPERESTRELLA UNIDOSS.L.</li>
                  <li>Agilizamos la concesión de tu préstamo rápido solicitándote solo la documentación imprescindible.</li>
                  <li>Transparencia. Desde el primer momento sabrás el importe de dinero que tienes que devolver y el plazo que tienes para hacerlo y puedes acceder a esa información sobre tu préstamo rápido en cualquier momento.</li>
                  <li>Si ves que la fecha de vencimiento de tu préstamo rápido está a punto de llegar y no puedes hacer frente a la devolución, puedes contratar una ampliación de tu préstamo rápido para alargar el plazo.</li>
                  <li>Desde que existen, los préstamos rápidos no han tenido buena fama por los altos intereses que supuestamente generan. En Prestamia, tenemos los intereses más bajos del mercado de los préstamos rápidos online.</li></div>
                
              </el-collapse-item>
              </div>
              <div class="active">
                <!-- <el-collapse-item title="¿El primero de los créditos es completamente gratis?" name="3">
                <div>Sí. El primero de tus créditos será de hasta 700€ sin intereses ni comisiones, por lo que es completamente gratuito. Es decir, si solicitas un minicrédito de 700€ a devolver en 30 días, solamente devolverás 700€ transcurridos los 30 días.</div>

                
              </el-collapse-item> -->
              <el-collapse-item title="¿Cobráis comisión de apertura?" name="26">
                <div>
                  <p>No hay ningún tipo de comisión de apertura al pedir préstamos rápidos online en Prestamia. Normalmente, las comisiones de apertura responden a los trámites necesarios para la formalización de los créditos rápidos y la entrega de dinero a los clientes, pero nosotros no te cobramos ninguna comisión en la solicitud de tus créditos rápidos. Normalmente, las comisiones más comunes a pagar en una solicitud son la comisión de apertura, la de estudio, y la de cancelación. Con nosotros no pagarás ninguna de ellas.</p>
                </div>
              </el-collapse-item>
              <el-collapse-item title="¿Cómo puedo pedir un préstamo rápido?" name="4">
                <div>
                  <p>Solicitar créditos rápidos con Prestamia es muy sencillo. Solo tienes que seguir estos simples pasos:</p>
                  <li>Regístrate. Para solicitar un préstamo rápido solo tienes que elegir el importe, el plazo de devolución y tus datos personales. La cantidad máxima de dinero que puedes solicitar en tu primer préstamo rápido es de 700€. Ten en cuenta que el plazo máximo de devolución del préstamo rápido es de 30 días.</li>
                  <li>Identifícate. A través de tu banco online podrás seleccionar tu banco, conectarte de forma segura y confidencial, y podremos comprobar tu información a través de la herramienta más segura del mercado, Tink. Inmediatamente sabrás si tu préstamo rápido en el acto ha sido aprobado.</li>
                  <li>Consigue tu dinero. Una vez tu préstamo rápido esté aprobado, te transferiremos tu dinero en menos de 15 minutos. Recibirás un email y un SMS de confirmación de tu préstamo rápido y tu dinero será enviado a tu cuenta en menos de 15 minutos.</li>
                </div>
              </el-collapse-item>
              </div>
              <div class="active">
                <el-collapse-item title="¿Qué requisitos tengo que cumplir para poder solicitar préstamos rápidos?" name="5">
                <div><p>Aquí tiene los requisitos para solicitar préstamos al instante con nosotros:</p>
                <p>1. Residir en España.</p>
                <p>2. Ser mayor de edad.</p>
                <p>3. Ser titular de una cuenta bancaria en España.</p>
                <p>4. No tener pagos pendientes o deudas.</p>
                </div>
                
              </el-collapse-item>
              <el-collapse-item title="¿De cuánto importe puedo solicitar mis créditos rápidos?" name="6">
                <div>
                  <p>El primero de tus préstamos rápidos puede ser de hasta 700€(dependiendo de tu edad). Una vez devuelvas el dinero inicial, podrás hacer la solicitud de más préstamos rápidos con un importe superior hasta un máximo de1.400€. Siempre a devolver en un plazo máximo de 30 días.</p>
                </div>
              </el-collapse-item>
              </div>
              <div class="active">
                <el-collapse-item title="¿Cómo puedo solicitar un segundo préstamo rápido?" name="7">
                <div>
                  <p>Cuando pides tu primer préstamo rápido a Prestamia, puedes conseguir un préstamo rápido de hasta 700 euros. Una vez que lo hayas devuelto (a tiempo), tendrás la posibilidad de solicitar un segundo préstamo rápido por un importe de dinero superior.</p>
                  <p>Para solicitar un segundo crédito rápido a Prestamia es necesario que cumplas con una serie de requisitos. Algunos de ellos son las mismas que se requieren para obtener tu primer préstamo rápido:</p>
                  <li>Residir en España.</li>
                  <li>Ser mayor de edad.</li>
                  <li>Ser titular en una cuenta bancaria dentro de España.</li>
                  <li>No tener ningún tipo de deuda o pago pendiente a terceros.</li>
                  <li>Haber realizado la devolución del primer préstamo rápido con Prestamia.</li>
                  <p>Existen muchas razones para pedir un segundo préstamo rápido. Por ello, te ofrecemos un gran número de ventajas y garantías y te permitimos tener dinero a tu disposición de manera sencilla y rápida.</p>
                </div>

                
              </el-collapse-item>
              <el-collapse-item title="¿Cuándo puedo realizar un aplazamiento en el pago de mis préstamos?" name="8">
                <div>
                  <p>Si llegado el día de vencimiento de vuestros préstamos rápidos, no te viene bien realizar el pago del microcrédito, o quieres disponer de tu dinero un tiempo adicional, puedas solicitar una ampliación del plazo, por 7, 14 o 30 días. Es decir, para seguir disponiendo de tu dinero puedes aplazar tu fecha de devolución de tus préstamos en 7, 14 o 30 días más, contando desde la fecha de vencimiento del préstamo. Puedes contratar esta ampliación del plazo incluso el día de la solicitud y posterior contratación del microcrédito y durante todo el período de vigencia del mismo, hasta 24/48 horas laborables antes del vencimiento, en el caso de pago mediante ingreso en cuenta o transferencia.</p>
                  <p>Si vas a pagar a través de tu tarjeta, puedes solicitar el aplazamiento el mismo día del vencimiento de tu préstamo.</p>
                </div>
              </el-collapse-item>
              </div>

              
              
              <div class="active_yc" v-if="key">
              <div class="active" >
                <el-collapse-item title="¿Puedo pagar mis créditos rápidos antes de la fecha de vencimiento?" name="11">
                <div>Podrás pagar el préstamo cuando tú quieras antes de la fecha de vencimiento pactada sin costes adicionales.</div>

                
              </el-collapse-item>
              <el-collapse-item title="Ya tengo un préstamo rápido online con Prestamia, ¿puedo solicitar más dinero?" name="12">
                <div>
                  <p>Puedes pedir más dinero siempre que no hayas gastado tu límite de crédito disponible. Podrás hacer una solicitud de un importe de dinero adicional hasta llegar a ese límite con la misma fecha de vencimiento del que el préstamo rápido online que ya tienes abierto.</p>
                </div>
              </el-collapse-item>
              
            
            
              </div>
              <div class="active" >
                <el-collapse-item title="Necesito más dinero, ¿cómo puedo ampliar el importe de mis préstamos rápidos?" name="13">
                <div>
                  <p>¿Has pedido uno de nuestros préstamos rápidos Prestamia pero crees que necesitas más dinero? Con los préstamos rápidos Prestamia puedes ampliar el importe de tu préstamo rápido online en cualquier momento accediendo a tu área de cliente Prestamia siempre y cuando tu límite de crédito actual te lo permita.</p>
                  <p>Tienes dos opciones para ampliar el importe de tus préstamos rápidos:</p>
                  <li>Si es tu primer préstamo rápido online, tu límite de crédito son 700euros (si tienes 24 años o más), por lo que solo podrías pedir más importe adicional en caso de que hubieras solicitado menos de 700 euros. Por ejemplo:si has pedido un primer préstamo rápido de 350 euros, podrías solicitar otros 350 euros hasta llegar al total de tu límite de crédito de 700. En este caso, al ser el primero de tus préstamos rápidos online, el importe adicional seguiría sin tener intereses ni comisiones.</li>
                  <li>Si se trata del segundo o posterior de tus préstamos rápidos online, en función de la cantidad de créditos rápidos que hayas devuelto a fecha de vencimiento, tu límite de crédito irá creciendo progresivamente hasta llegar aun máximo de 1.400 euros. Por ejemplo, si eres cliente desde hace tiempo en Prestamia y tu límite de crédito son 800 euros y hoy has pedido un préstamo de 300euros, seguirás disponiendo en cualquier momento de un importe adicional de hasta 500 euros.</li>
                  <p>Recuerda que aunque pidas más dinero, la fecha de vencimiento de tus préstamos rápidos online sigue siendo la misma.</p>
                </div>

                
              </el-collapse-item>
              <el-collapse-item title="¿Puedo pedir varios préstamos rápidos online a la vez?" name="14">
                <div>
                  <p>Mientras tengas un préstamo abierto, no puedes solicitar otro préstamo nuevo. Una vez hayas pagado el préstamo, podrás solicitar un nuevo préstamo rápido nuevo. Sin embargo, si tienes un préstamo abierto podrás solicitar una ampliación del crédito hasta llegar a tu límite de crédito, con un máximo de 1.400€.</p>
                </div>
              </el-collapse-item>
              
            
            
              </div>
              <div class="active" >
                <el-collapse-item title="Si pago el total de mi minicrédito, ¿puedo solicitar otro el mismo día?" name="15">
                <div>Si realizas el pago con tarjeta, en el mismo momento se actualizará el nuevo importe disponible, que puede aumentar o no. Así que en este caso sí, podrías solicitar un nuevo mini crédito de inmediato. No obstante, si realizas el pago mediante transferencia bancaria, el pago podría tardar hasta dos días en llegarnos, por lo que no podrás pedir un nuevo mini crédito deforma inmediata.</div>

                
              </el-collapse-item>
              <el-collapse-item title="¿Cuál es mi número de préstamo?" name="16">
                <div>
                  <p>Puedes consultar el número de tus préstamos en tu área de cliente. El número de tus préstamos es el identificador único que te sirve cuando necesitas hacer una gestión del préstamo.</p>
                </div>
              </el-collapse-item>
              
            
            
              </div>
              <div class="active" >
                <el-collapse-item title="¿Cómo puedo saber el estado de mis préstamos rápidos?" name="17">
                <div>
                  <p>Si has pedido uno o más préstamos rápidos, lo más seguro es que estés deseando recibir tu dinero. Si es el primero de tus préstamos, para saber cuando vas a recibir tu dinero, deberás haber rellenado tu solicitud de préstamo con su posterior identificación. Una vez hayas realizado estos dos pasos, sabrás si el primero de tus préstamos está aprobado o no. En caso afirmativo, desde el momento en el que es aprobado, nosotros hacemos la transferencia del dinero a tu cuenta en aproximadamente 15 minutos. Dependiendo del banco con el que operes, el dinero podrá llegar de manera inmediata, o en algunos casos, en las próximas 24/48 horas laborables.</p>
                  <p>Desde ese preciso momento, puedes comprobar la fecha de vencimiento de tus préstamos y toda la información relativa al estado de tus préstamos en tu área de clientes.</p>
                  <p>Si es el segundo de tus préstamos o posterior, como ya estás registrado, sabrás en el momento de tu solicitud si tu préstamo ha sido aprobado o no. Y a partir de es instante, puedes comprobar la fecha de vencimiento y toda la información relativa al estado de tu préstamo en tu área de clientes.</p>
                </div>

                
              </el-collapse-item>
              <el-collapse-item title="¿Para solicitar un primer préstamo tengo que enviar documentación?" name="18">
                <div>
                  <p>Para solicitar todo tipo de préstamos rápidos online, tendrás que identificarte de manera automática (a través de Tink) y podrás conocer si tu préstamo rápido es aprobado al instante.</p>
                </div>
              </el-collapse-item>
              
            
            
              </div>
              <div class="active" >
                <el-collapse-item title="¿Puedo pedir préstamos rápidos sin banca online?" name="19">
                <div>El proceso de identificación en Prestamia se realiza de manera automática a través de Tink. Para ello, sí que es necesario tener banca online.</div>

                
              </el-collapse-item>
              <el-collapse-item title="¿Qué pasa si pido mis préstamos rápidos por la noche?" name="20">
                <div>
                  <p>Puedes pedirlo en la hora que sea. Si pedís vuestros préstamos rápidos por la noche, a través de nuestra web, nosotros transferimos el dinero en un plazo máximo de 15 minutos. Al estar fuera del horario laboral, es posible que tu banco no procese la tranferencia hasta el día siguiente. La entidad bancaria tiene un plazo máximo de 48 horas para procesar el pago, por lo que dependiendo del banco con el que operes, recibirás el dinero la mañana siguiente o hasta 24/48 horas después.</p>
                </div>
              </el-collapse-item>
              
            
            
              </div>
              <div class="active" >
                <el-collapse-item title="¿Qué pasa si solicito mis préstamos durante el fin de semana?" name="21">
                <div>Igual que si pides tus préstamos el resto de los días de la semana. Si pedís vuestros préstamos un sábado, un domingo o un festivo, nosotros haremos la transferencia del dinero de estos préstamos en un plazo máximo de 15 minutos. No obstante, cabe la posibilidad de que el banco no procese esa transferencia hasta el próximo día laborable. En algunos casos puede que no recibas el dinero hasta 24/48 horas después.</div>

                
              </el-collapse-item>
              <el-collapse-item title="¿Puedo pedir un crédito si estoy en ASNEF?" name="22">
                <div>
                  <p>ASNEF son las siglas de la Asociación Nacional de Establecimientos Financieros.</p>
                  <p>En Prestamia, cuando se recibe una solicitud de préstamo rápido, hacemos diversas comprobaciones relativas al historial crediticio del solicitante. Una de ellas, consiste en comprobar si ese posible cliente se encuentra en ficheros de morosidad, como por ejemplo, ASNEF. En caso de que sea así, se trata de un factor importante a la hora de otorgar o no el crédito, pero no es la única ni es excluyente.</p>
                </div>
              </el-collapse-item>
              
            
            
              </div>
              <div class="active" >
                <el-collapse-item title="¿Cómo puedo salir de ASNEF si quiero pedir créditos rápidos?" name="23">
                <div>
                  <p>La forma más sencilla y rápida para salir de ASNEF es pagando tu deuda. Estar incluido en ASNEF resulta algo más habitual de lo que se pueda imaginar. Únicamente por el impago de algún recibo pueden incluir nuestros datos en el fichero de morosos ASNEF.</p>
                  <p>Por otra parte, los datos incluidos en ASNEF tienen un plazo de prescripción de 6 años. La ley permite que se conserven tus datos en el fichero ASNEF durante todo ese tiempo, pero posteriormente podrías solicitar que sean borrados de la ASNEF. No obstante, no parece una buena opción esperar 6 años para solicitar un préstamo rápido si estamos en ASNEF.</p>
                </div>

                
              </el-collapse-item>
              <el-collapse-item title="¿Por qué denegáis mis préstamos rápidos?" name="24">
                <div>
                  <p>La solicitud de vuestros préstamos rápidos está sujeta a las correspondientes verificaciones. Tu solicitud tiene que cumplir los requisitos exigidos en nuestra política de créditos al consumo, antes de aprobarse. Prestamia.es podrá recabar información relativa a los antecedentes crediticios y posiciones de riesgo de entidades prestadoras de servicios de información sobre solvencia patrimonial y crédito, para el análisis y, en tal caso, la concesión, seguimiento y control de la financiación.</p>
                </div>
              </el-collapse-item>
              
            
            
              </div>
              <div class="active" style="justify-content: normal; margin-left: 3.5vw;">
                <el-collapse-item style="width: 42%;" title="¿Cuáles son las consecuencias de no realizar el pago a tiempo?" name="25">
                <div>
                  <p>Devolver tus préstamos rápidos online a tiempo es muy importante si quieres mantener tu historial crediticio limpio de cara a futuras peticiones. Por eso, es fundamental que antes de solicitar préstamos rápidos online, estés seguro/a de que puedes devolverlo en la fecha acordada, para evitar, no sólo, ser incluido en listas de morosidad (ASNEF), sino también incurrir en más gastos que tengan relación con los intereses de demora o las comisiones por deuda. No obstante, si estás pasando por apuros financieros y no eres capaz de pagar tus préstamos rápidos, ten en cuenta los siguientes consejos que te damos:</p>
                  <li>Nunca solicites más préstamos rápidos a otras entidades para pagar la deuda actual.</li>
                  <li>Contacta con nuestro servicio de atención al cliente y ellos te ayudarán a encontrar la mejor solución a tu problema. Escríbenos explicándonos tu caso a officialservicio@prestamiaes.com o llama al +34 643251527.</li>
                  <li>No dejes pasar el problema: cuanto más tiempo se posponga, más difícil será resolverlo.</li>
                  <li>Revisa tu presupuesto mensual, en busca de algún gasto que puedas reducir.</li>
                  <p>El impago de tu préstamo rápido conlleva una penalización del1,10% diario sobre el importe impagado, con el límite máximo del 200% sobre el principal, así como los gastos ocasionados por el impago del crédito.</p>
                </div>

              </el-collapse-item>

              
            
            
              </div>
              
              </div>
              <p class="ii" ><span @click="key = !key">VER MÁS</span></p>
          </el-collapse>
          </div>
          
        </div>
      </div>
      <div class="foot a">
        <div class="foot_middle">
          <p><span>Prestamista: TRES PULPOS S.L.UNIPERSONAL
            Sociedad de nacionalidad española, con domicilio social, ATOCHA,125 04 1Z - 28012 Madrid) B10756781, inscrita en el Registro Mercantil de Madrid, al Tomo 44431, asiento 1141, folio 63, inscripción 3 con hoja M-782952.</span> Su actividad no está sometida a la regulación y supervisión del Banco de España. </p>
          <p>Términos y condiciones aplicables: El solicitante ha de ser una persona física, mayor de edad y con domicilio permanente en España. Importe del primer préstamo de hasta 700€ sin intereses ni comisiones y con una duración de 7 a 30 días naturales. En siguientes contrataciones, el solicitante podrá escoger el importe y el plazo deseado a través del simulador en su área de cliente y visualizar la TAE y el importe total a pagar. Todas las solicitudes de préstamo están sujetas a aprobación del Prestamista. </p>
          <p>A modo de ejemplo, para un préstamo de 100€ a devolver en 30 días, los intereses serían de 32€, lo que equivale a una TAE del 2.830,8% y un  TIN del 389,33%. En caso de impago, la penalización por mora será del 1,1% diario sobre el principal impagado, con un máximo del 200% sobre el principal del préstamo impagado.</p>
        </div>
        <!-- <p class="ii" ><span @click="pull">VER MÁS</span></p> -->
      </div>
      <div class="con">
        <!-- <p>Configuración de cookies</p> -->
      </div>
      <div class="abcss">
        <div class="abcss_middle">
          <div class="abcss_left">
              <h4>¿Necesitas ayuda?</h4>
              <p>¡Resolvemos todas tus dudas!</p>
              <p><a href="https://www.prestamiaes.com" style="color: #fff;text-decoration: underline;">Consulta las preguntas más frecuentes</a></p>
              <p><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiacreditosrapidoss/privacy.html">privacy</a></p>
              <p style="padding: 1vw 0px;"><a style="color: #fff;font-size: 17px;text-decoration: underline !important;" href="https://www.prestamiaes.com/prestamiacreditosrapidoss/terms.html">Términos y condiciones</a></p>
          </div>

          <div class="abcss_right">
            <div class="tt">
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/phone.png" alt=""></span> +34 643251527</p>
              <p style="display: flex;align-items: center;"><span style="height: 70%;"><img style="width: 25px;height: 25px;" src="../assets/img/sms.png" alt=""></span>officialservicio@prestamiaes.com</p>
            </div>
            <div class="jj">
              <p>Horario de atención:</p>
              <p>‍Lunes – Viernes / 8:00 – 21:00</p>
              <p>Sábado / 9:00 – 20:00</p>
              <p>Domingo / 9:00 – 19:00</p>
              <p>Festivos nacionales / 9:00 – 14:00</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="footer">
        <div class="footer_middle">
          <div class="footer_logo">
            <img src="../assets/logo.png" alt="">
          </div>
          <div class="footer_box">
            <p>Cómo funciona</p>
            <p>Ofertas</p>
            <p>Blog</p>
            <p>Contacto</p>
            <p>Sobre nosotros</p>
            <p>Préstamo responsable</p>
            <p>Aviso legal</p>
          </div>
          <div class="footer_box">
            <p>Aplicación movil</p>
            <p>Formulario de desistimiento</p>
            <p>Canal de denuncias</p>
            <p>Política de cookies</p>
            <p>Términos y condiciones</p>
            <p>Política de privacidad</p>
          </div>
          <div class="footer_box">
            <p>Créditos online</p>
            <p>Créditos rápidos</p>
            <p>Préstamos personales online</p>
            <p>Préstamos rápidos</p>
            <p>Simulador préstamos</p>
            <p>Sobre nosotros</p>
            <p>Préstamo responsable</p>
            <p>Crédito fácil</p>
            <p>Préstamos sin nómina sin aval</p>
            <p>Micropréstamos online</p>
            <p>Dinero rápido</p>
            <p>Minicréditos online rápidos</p>
            <p>¿Qué son los minipréstamos online?</p>
          </div>
          <div class="footer_box">

          </div> 
           <div class="footer_box">
            <div class="footer_boxx">
              <div class="footer_img1">
                <img src="../assets/img/google.svg" alt="">
              </div>
              <div class="footer_img1">
                <img src="../assets/img/apple.svg" alt="">
              </div>
            </div>
            <div class="footer_boxxx">
              <div class="footer_img2">
                <img src="../assets/img/d1.svg" alt="">
              </div >
              <div class="footer_img2">
                <img src="../assets/img/d2.svg" alt="">
              </div>
              <div  class="footer_img2">
                <img src="../assets/img/d3.svg" alt="">
              </div>
              <div  class="footer_img2">
                <img src="../assets/img/d4.svg" alt="">
              </div>
              <div  class="footer_img2">
                <img src="../assets/img/d5.svg" alt="">
              </div>
            </div>
          </div> 
        </div>
      </div> -->
  </div>
</template>

<script>
  import NavBox from './NavBox.vue'
  import dayjs from 'dayjs';
  export default {
    metaInfo : {
    title:'Prestamia',
    meta:[
      {
      name:'Prestamia | España',
      content:'PIDE TU PRÉSTAMO RÁPIDO Y SIÉNTETE Prestamia!'
    },
    {
      name:'description',
      content:'Tu primer crédito, de hasta 700€, sin intereses ni comisiones'
    },
    {
      name:'Cómo solicitar un crédito rápido',
      content:'Solicita un préstamo online sin intereses con Prestamia es sencillo y 100% seguro'
    },
    {
      name:'description',
      content:'Más de 1.9 millones de usuarios satisfechos'
    },
    {
      name:'¡Descárgate la app de Prestamia!',
      content:'Gestiona tu préstamo desde donde tú quieras | De forma fácil, rápida y segura'
    }
  ]
  },
    components:{
      NavBox
    },
    data() {
      return {
        key:false,
        value: 50,
        value1:7,
        activeNames: [''],
        time:'',
        active: 0
      }
    },
    created() {
      this.a()
    },
    methods: {
      handleChange(val) {
        // console.log(val);
      },
      a(){
        let d= dayjs(Date.now())+this.value1*86400000
        this.time =  dayjs(d).format('DD/MM/YYYY')
        // console.log('aaaa')
      },
      next() {
        if (this.active++ > 4) this.active = 0;
      }
    }
  }

</script>
<!-- 1030 -->
<style scoped>
@import url(../assets/css/index.css);
a {
  color: #000;
  text-decoration: none;
}
img {
  width: 100%;
  height: 100%;
}
.logo {
  display: flex;
  align-items: center;
  padding-left: 12vw;

}
.logo_img {
  cursor: pointer;
  width: 5vw;
}
.nav {
  height: 50vw;
  background-image: url(../assets/img/nav.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  border-top: 1px solid #fff;
}
.nav_middle {
  display: flex;
  justify-content: space-between;
  width: 75%;
  height: 70%;
  margin: auto;
  margin-top: 3vw;
  border-radius: 10px;
}
.nav_left {
  width: 40%;
  color: #fff;
  font-size: 30px;
  font-weight: 700;

}
.nav_left > p {
  padding: 10px;
}
.nav_left > p:nth-child(2) {
  font-size: 20px;
  color: #a6ce38;
  padding: 2vw 0px;
}
.nav_xx {
  font-size: 18px;
}
.nav_xx > p {
  padding: 10px;
}
.dg {
  display: inline-block;
  width: 1vw;
  padding-right: 1vw;
}
.dg > img{
  display: inline;
}
.nav_right {
  opacity: 1;
  width: 380px;
  height: 500px;
  /* width: 35%; */
  border-radius: 10px;

}
.nav_right_top {
  font-weight: 700;
  font-size: 23px;
  color: #fff;
  height: 18vw;
  background-color: rgb(166, 206, 57);
  border-radius: 10px 10px 0px 0px;

}
.nav_right_top_xx {
  padding: 0px 2vw;
}
.nav_right_top_xx > div >p:nth-child(2) {
  font-size: 15px;
}
.el-slider__runway > .el-slider__bar {
  background-color: rgb(27 125 71) !important;
}
.nav_right_button_xx {
  padding: 1vw 2vw;
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
}

.nav_right_button_xx > div > button {
  display: block;
  margin: 1vw auto;
  text-align: center;
  padding: 10px 10px;
  background-color: rgb(27 125 71);
  border: 0px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  border-radius: 7px;
  cursor: pointer;
}
.nav_right_button_xx > div > a {
  display: block;
  font-size: 12px;
  text-align: center;
  color: #000;
}

.main_top {
  margin:2vw auto 1vw;
  width: 40%;
  text-align: center;
  font-size: 30px;
}
.main_top > p {
  padding-bottom: 1vw;
}
.main_top > p:nth-child(2) {
  font-size: 20px;
  color: #65696e;
}
hr {
  width: 10%;
  margin: auto;
  background-color: #116c3a;
  height: 3px;
  border-radius: 10px;
}
.main_nr {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
  width: 70%;
  margin: auto;
}
.main_nr_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 15vw;
}
.main_nr_box {
  margin-top: 4vw;
}
.main_nr_box:nth-child(2) {
  margin-top: 0vw;
  margin-left:4vw;
  margin-right: 4vw;
}
.main_img {
  width: 5vw;
  height: 5vw;
  margin: 0px auto 1vw;
}
.main_nr_box > h4 {
  color: #65696e;
  font-size: 17px;
  text-align: center;
}
.GG > p {
  padding-bottom: 1vw;
  font-size: 12px;
}
.gg {
  color: #a7cc46;
}
.ben {
  background-color: #f6f6f9;
  padding-bottom: 50px;
}
.ben_top {
  width: 50%;
  margin: auto;
  text-align: center;
  border: 1px solid #f6f6f9;
}
.ben_top_img {
  margin: 5vw auto 0px;
  width: 6vw;
}
.ben_top > h3 {
  font-size: 40px;
  color: #116c3a;
  padding-bottom: 1vw;
}
.ben_top > p {
  font-size: 20px;
  color: #65696e;
}
.ben_d_img {
  width: 50%;
  margin: 2vw auto 0px;
}
.btn {
  display: block;
  margin: 1vw auto;
  text-align: center;
  padding: 15px 20px;
  background-color: rgb(27 125 71);
  border: 0px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  border-radius: 7px;
  cursor: pointer;


}

.vv > img {
  position: absolute;
  top: -2vw;
  left: 2vw;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  border: 2px solid #fff;
}

.oocss {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #fff;
  background-color: #116c3a;
  color: #fff;
  padding: 5vw 0px;
}
.oocss > h2 {
  font-size: 35px;
}
.oocss > p {
  padding: 2vw 0px;
  font-size: 20px;
}
.oocss > button {
  display: block;
  margin: 1vw auto;
  text-align: center;
  padding: 15px 20px;
  background-color: #a7cc46;
  border: 0px;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  border-radius: 7px;
  cursor: pointer;
}

.section {
  border-top: 1px solid #fff;
  background-color: #eff3f5;
}
.section_top {
  padding-top: 4vw;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}
.section_d_middle {
  margin-top: 4vw;
}
.active {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1vw;
}

.el-collapse {
  width: 70%;
  margin: auto;

}
.el-collapse-item {
  width: 40%;
}
::v-deep .el-collapse-item__header {
  background-color: #fff;
  /* margin-bottom: 1vw; */
  padding-left: 1vw;
  font-weight: 700 !important;
  font-size: 17px !important;
  height: 100% !important;
}
::v-deep .el-collapse-item__content {
  font-size: 14px !important; 
  padding: 0px 1vw !important;
  font-size: 16px !important;
}
.ii {
  font-size: 12px;
  text-align: center;
  padding: 3vw 0px;
  display: inline-block;
  margin: auto;
  width: 100%;
  background-color: #eff3f5;
}
.ii > span {
  cursor: pointer;
}
.foot {
  background-color: #ebedf2;
  border: 1px solid #ebedf2;
}
.foot_middle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  margin: 5vw auto;
  font-size: 12px;
  color: #65696e;
}
.con {
  height: 3vw;
  line-height: 3vw;
  text-align: center;
  font-size: 12px;
  color: #116c3a;
  text-decoration: underline;
  background-color: #fff;
}
.con > p {
  cursor: pointer;
}

.footer {
  height: 10vw;
  background-color: #fff;
  border-top: 1px solid #fff;
}
.footer_middle {
  display: flex;
  justify-content: space-between;
  padding-top: 3vw;
  width: 90%;
  height: 100%;
  margin: auto;
}
.footer_box > p {
  cursor: pointer;
  line-height: 2;
  font-size: 12px;
}
.footer_box:nth-child(4) {
  display: flex;
}
.footer_img {
  cursor: pointer;
  width: 7vw;
  height: 7vw;
  margin: 1vw;
}
.footer_boxx{
  display: flex;
}
.footer_img1 {
  cursor: pointer;
  width: 8vw;
  margin-right: 1vw;
}
.footer_boxxx {
  margin-top: 2vw;
  display: flex;
  justify-content: space-between;
}
.footer_img2 {
  width: 2vw;
  cursor: pointer;
}
.footer_logo {
  width: 6vw;
  height: 6vw;
}
::v-deep .el_wz > p > span {
    font-size: 15px;
}
::v-deep .el-step__head.is-finish {
    color: #116c3a !important;
    border-color: #116c3a !important;
}
::v-deep .el-step__title.is-process {
    color: #116c3a;
}
::v-deep .el-step__title.is-finish {
    color: #116c3a;
}
::v-deep .el-button {
    display: block;
    text-align: center;
    margin: auto;
    
    color: #116c3a;
}
</style>
<style>
 @media screen and (max-width:1000px) {
  .nav_xx {
    display: none;
  }
 }
</style>
